body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

div#root {
  width: 100%;
  height: 100%;
}
